/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx } from "theme-ui";
import { StringParam, useQueryParams } from "use-query-params";

import LoginView from "../components/LoginView";
import MasterLayout from "../components/MasterLayout";
import { googleLogin, login } from "../http/endpoints";

const IndexPage = memo(function IndexPage(): ReactElement {
  const [{ redirectUrl, inviteToken, initialEmail }] = useQueryParams({
    redirectUrl: StringParam,
    inviteToken: StringParam,
    initialEmail: StringParam,
  });

  return (
    <MasterLayout title="Login">
      <LoginView
        redirectUrl={redirectUrl ?? undefined}
        inviteToken={inviteToken ?? undefined}
        initialEmail={initialEmail ?? undefined}
        login={login}
        googleLogin={googleLogin}
      />
    </MasterLayout>
  );
});
export default IndexPage;
