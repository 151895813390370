/** @jsx jsx */
import { DUIButton, DUIInput, DUIText } from "@alch/ui";
import useIdentifyUser from "@hooks/useIdentifyUser";
import { memo, ReactElement, useCallback, useEffect, useState } from "react";
import { Box, BoxProps, jsx } from "theme-ui";

import {
  GoogleLoginParams,
  LoginParams,
  LoginResult,
  MFVChallengeParams,
} from "../http/endpoints";
import { Loading, useWaitForLoad } from "../util/loading";
import { getMfvParamsFromResult, hasMfvChallenge } from "../util/mfvUtils";
import DUIInputLabel from "./DUIInputLabel";
import GoogleSignInButton from "./GoogleSignInButton";
import HCaptchaModal from "./HCaptchaModal";
import MultiFactorVerificationModal from "./MultiFactorVerificationModal";
import OrSeparator from "./OrSeparator";
import QueryLink from "./QueryLink";
import Section from "./Section";
import SsoSignInButton from "./SsoSignInButton";
import { fullWidthSx, linkSx, linkSxLight } from "./styles";
import TermsOfService from "./TermsOfService";
import ValidatingForm from "./ValidatingForm";

export interface LoginFormProps extends BoxProps {
  redirectUrl?: string;
  inviteToken?: string;
  initialEmail?: string;
  login(params: LoginParams): Promise<LoginResult>;
  googleLogin(params: GoogleLoginParams): Promise<LoginResult>;
}

const LoginView = ({
  redirectUrl,
  inviteToken,
  initialEmail,
  login,
  googleLogin,
  ...boxProps
}: LoginFormProps): ReactElement => {
  const [email, setEmail] = useState(initialEmail ?? "");
  const [password, setPassword] = useState("");
  const [result, setResult] = useState(Loading.unloaded<LoginResult>());
  const [hCaptchaModalCallback, setHCaptchaModalCallback] =
    useState<React.ComponentProps<typeof HCaptchaModal>["onVerify"]>();
  const waitForLoad = useWaitForLoad();
  const [mfvChallengeParams, setMfvChallengeParams] =
    useState<MFVChallengeParams>();
  const [signupParams, setSignupParams] = useState<GoogleLoginParams>();
  const identifyUser = useIdentifyUser();
  const isHcaptchaModalOpen = !!hCaptchaModalCallback;
  const isMFVModalOpen = !!mfvChallengeParams;

  const handleLogin = useCallback(async () => {
    setResult(Loading.inProgress());
    waitForLoad(
      login({ email, password, redirectUrl, inviteToken }),
      setResult,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, [login, email, password, redirectUrl, inviteToken]);

  const handleGoogleLogin = useCallback(
    (code: string) => {
      if (typeof window !== `undefined`) {
        setHCaptchaModalCallback(() => (hCaptchaToken: string) => {
          setResult(Loading.inProgress());
          const params = {
            code: code,
            redirectUrl,
            inviteToken,
            sessionId: (window as any)._sift_session_id,
            hCaptchaToken,
          };
          setSignupParams(params);
          waitForLoad(googleLogin(params), (result) => {
            if (hasMfvChallenge(result)) {
              setMfvChallengeParams(getMfvParamsFromResult(result));
              setResult(Loading.unloaded<LoginResult>());
            } else {
              setResult(result);
            }
          });
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
    [googleLogin],
  );

  useEffect(() => {
    const redirectTo = result.value?.redirectTo;
    if (redirectTo) {
      identifyUser(() => {
        window.location.href = redirectTo;
      });
    }
  }, [identifyUser, result.value?.redirectTo]);

  return (
    <Box {...boxProps}>
      <Section centered={true}>
        <DUIText size="2xl">Login</DUIText>
      </Section>
      <Section shaded={true}>
        <div sx={{ display: "flex", flexDirection: "column", gap: "14px" }}>
          <GoogleSignInButton
            action="Sign in"
            onSuccess={handleGoogleLogin}
            onFailure={console.log}
          />
          <SsoSignInButton action="Sign in" />
        </div>
        <OrSeparator my={4} />
        <ValidatingForm onSubmitPreventDefault={handleLogin}>
          <DUIInputLabel text="Email">
            <DUIInput
              type="email"
              placeholder="gavin@hooli.com"
              autoComplete="email"
              required={true}
              value={email}
              onValueChange={setEmail}
            />
          </DUIInputLabel>
          <DUIInputLabel mt={5} text="Password">
            <DUIInput
              type="password"
              placeholder="••••••••"
              required={true}
              minLength={6}
              autoComplete="password"
              value={password}
              onValueChange={setPassword}
            />
          </DUIInputLabel>
          <Box mt={1}>
            <QueryLink sx={linkSxLight} to="/reset-password">
              Forgot your password?
            </QueryLink>
          </Box>
          <DUIButton
            mt={5}
            sx={fullWidthSx}
            size="lg"
            type="submit"
            disabled={result.isLoading}
          >
            Log in
          </DUIButton>
          {result.error && (
            <DUIText mt={3} sx={{ color: "danger.core" }}>
              {result.error.message ?? "An unknown error occurred."}
            </DUIText>
          )}
          <DUIText
            mt={3}
            mb={4}
            sx={{ fontWeight: 500, color: "gray.2" }}
            size="md"
          >
            Don't have an account?{" "}
            <QueryLink sx={linkSx} to="/signup">
              Signup
            </QueryLink>
          </DUIText>
          <TermsOfService action={"Log in"} />
        </ValidatingForm>
      </Section>

      <HCaptchaModal
        onVerify={hCaptchaModalCallback}
        isOpen={isHcaptchaModalOpen}
        onClose={() => setHCaptchaModalCallback(undefined)}
      />

      <MultiFactorVerificationModal
        isOpen={isMFVModalOpen}
        onClose={() => {
          setMfvChallengeParams(undefined);
          setResult(Loading.unloaded<LoginResult>());
        }}
        mfvChallengeParams={mfvChallengeParams}
        setResult={setResult}
        signupParams={signupParams}
        signupType={"Google"}
      />
    </Box>
  );
};

export default memo(LoginView);
